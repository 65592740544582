<template>
   <div class="main-articles " v-if="$get(contentData, 'data.listCount')">
      <div class="item" v-for="item in $get(contentData, 'data.list') " :key="item.id">
         <div class="title-wrap mob">
            <h2 class="title" v-html="$get(item, 'title')"></h2>
            <h3 v-if="$get(item, 'additionat_info')" class="subtitle">{{$get(item, 'additionat_info')}}</h3>
         </div>
         <div class="img-box">
            <router-link :to="$get(item, 'link.url')">
               <picture>
                  <source media="(max-width: 767px)" :srcset="$get(item, 'mobile_image.0.devices.desktop')" />
                  <source data-scroll-speed="-1" media="(max-width: 1023px)"
                     :srcset="$get(item, 'image.0.devices.tablet')" />
                  <img data-scroll data-scroll-speed="-1" :src="$get(item, 'image.0.devices.desktop')"
                     :alt="$get(item, 'image.0.title')" />
               </picture>
            </router-link>
         </div>
         <div class="content">
            <div class="title-wrap">
               <h2 class="title" v-html="$get(item, 'title')"></h2>
               <h3 v-if="$get(item, 'additionat_info')" class="subtitle">{{$get(item, 'additionat_info')}}</h3>
            </div>
            <p class="teaser">
               {{ $get(item, 'teaser') }}
            </p>
            <router-link :to="$get(item, 'link.url')" class="arrow">
               <svg width="114" height="118" viewBox="0 0 114 118" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd"
                     d="M104.918 57.0742L0.749126 57.0742L0.749127 62.0742L103.77 62.0742L51.4221 114.422L54.9577 117.958L112.148 60.7678L113.915 59L112.148 57.2322L54.9577 0.0423297L51.4221 3.57786L104.918 57.0742Z"
                     fill="#857EAB" />
               </svg>
            </router-link>
         </div>
      </div>
   </div>
</template>

<script>
import locomotiveScroll from "locomotive-scroll";
export default {
   name: 'Main_Articles',
   props: {
      contentData: {}
   }
}
</script> 

<style lang="scss" scoped>
.main-articles {
   padding: 144px 120px 0;
}

.item {
   flex: none;
   display: flex;
   align-items: center;
   margin-bottom: 233px;

   &:last-child {
      margin-bottom: 0;
   }
}

.item:nth-child(odd) {
   .content {
      margin-left: 172px;
      max-width: 683px;
   }

   .img-box {
      flex: none;
      width: 40.65%;
   }

   .img-box img {
      display: block;
      width: 100%;
   }
}

.item:nth-child(even) {
   flex-direction: row-reverse;

   .content {
      margin-right: 30px;
      max-width: unset;
   }

   .img-box {
      flex: none;
      width: 57.55%;
   }

   .img-box img {
      display: block;
      width: 100%;
   }
}

.img-box {
   overflow: hidden;
}

.item:nth-child(1) .img-box {
   clip-path: polygon(0% 21.6%, 33.7% 21.6%, 33.7% 0%, 69.3% 0%, 69.3% 8.3%, 100% 8.3%, 100% 100%, 13% 100%, 13% 77.94%, 0% 77.94%);
}

.item:nth-child(2) .img-box {
   clip-path: polygon(0% 16.19%, 11.08% 16.19%, 11.08% 0%, 85.53% 0%, 85.53% 6.5%, 100% 6.5%, 100% 82.67%, 89.26% 82.67%, 89.26% 90.2%, 79.02% 90.2%, 79.02% 100%, 0% 100%);
}

.item:nth-child(3) .img-box {
   clip-path: polygon(0% 18.09%, 3.84% 18.09%, 3.84% 15.12%, 6.52% 15.12%, 6.52% 12.86%, 22.28% 12.86%, 22.28% 0%, 85.51% 0%, 85.61% 61.98%, 100% 61.98%, 100% 97.67%, 92.44% 97.67%, 92.44% 100%, 0% 100%);
}

.content {
   flex: 1;
}

.title-wrap{
   &.mob{
      display: none;
   }
}
.title {
   font-size: 64px;
   line-height: 77px;
   text-transform: uppercase;

   ::v-deep span {
      text-transform: capitalize;
   }
}

.subtitle {
   font-size: 34px;
   line-height: 41px;
}

.teaser {
   font-size: 21px;
   line-height: 30px;
   margin-top: 21px;
}

.arrow {
   display: block;
   margin-top: 34px;
   transition: .4s;
}

.arrow:hover {
   transform: translateX(10px);
}

@media screen and(max-width: 1900px) {
   .main-articles {
      padding: 99px 100px 0;
   }

   .item {
      margin-bottom: 167px;
   }

   .content {
      max-width: 498px;
   }

   .teaser {
      margin-top: 20px;
   }

   .arrow {
      margin-top: 34px;

      svg {
         display: block;
         width: 118px;
         height: auto;
      }
   }

   .item:nth-child(odd) {
      .content {
         margin-left: 136px;
         max-width: 500px;
      }

      .img-box {
         width: 48.75%;
      }
   }

   .item:nth-child(even) {
      .content {
         margin-right: 106px;
      }

      .img-box {
         width: 51.2%;
      }
   }
}

@media screen and(max-width: 1439px) {
   .main-articles {
      padding: 81px 80px 0;
   }

   .item {
      margin-bottom: 160px;
   }

   .content {
      max-width: 449px;
   }

   .title {
      font-size: 48px;
      line-height: 58px;
   }

   .teaser {
      font-size: 30px;
      line-height: 36px;
   }

   .arrow {
      margin-top: 34px;

      svg {
         display: block;
         width: 96px;
         height: auto;
      }
   }

   .item:nth-child(odd) {
      .content {
         margin-left: 124px;
      }

      .img-box {
         width: 48.84%;
      }
   }

   .item:nth-child(even) {
      .content {
         margin-right: 133px;
      }

      .img-box {
         width: 48.57%;
      }

   }
}

@media screen and(max-width: 1279px) {
   .main-articles {
      padding: 48px 48px 0;
   }

   .item {
      margin-bottom: 84px;
   }

   .title {
      font-size: 28px;
      line-height: 34px;
   }

   .subtitle {
      font-size: 21px;
      line-height: 28px;
   }

   .teaser {
      font-size: 18px;
      line-height: 22px;
      margin-top: 16px;
   }

   .arrow {
      margin-top: 34px;

      svg {
         display: block;
         width: 61px;
         height: auto;
      }
   }

   .content {
      max-width: 270px;
   }

   .item:nth-child(odd) {
      .content {
         margin-left: 74px;
      }

      .img-box {
         width: 48.80%;
      }
   }

   .item:nth-child(even) {
      .content {
         margin-right: 80px;
      }

      .img-box {
         width: 48.51%;
      }
   }
}

@media screen and(max-width: 767px) {
   .main-articles {
      padding: 18px 16px 0;
   }

   .item {
      margin-bottom: 45px;
      flex-direction: column;
      align-items: flex-start;
   }

   .title-wrap {
      display: none;
      margin-bottom: 34px;

      &.mob {
         display: block;
      }
   }

   .title {
      font-size: 28px;
      line-height: normal;
   }


   .teaser {
      font-size: 16px;
      line-height: 19px;
      margin-top: 34px;
   }

   .arrow {
      margin-top: 24px;

      svg {
         width: 66px;
      }
   }

   .content {
      max-width: 100%;
      margin-left: 0;
      margin-right: 0;
   }

   .item:nth-child(odd) {
      .content {
         margin-left: 0;
      }

      .img-box {
         padding-right: 0;
      }

      .img-box img {
         width: 100%;
      }
   }

   .img-box {
      width: 100% !important;
   }

   .item:nth-child(even) {
      flex-direction: column;

      .content {
         margin-right: 0;
      }

      .img-box {
         padding-left: 0px;
      }

      .img-box img {
         width: 100% !important;
         height: unset;
         object-fit: cover;
      }
   }
}
</style>